<!-- 考生考场安排 -->
<template>
    <div class="Arrange">
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="120px">
            <el-form-item label="制单日期">
                <el-date-picker v-model="form.makerDate" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="考试日期">
                <el-date-picker v-model="form.testDate" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="审核状态">
                <el-select v-model="form.examine" placeholder="请选择审核状态" :clearable="true">
                    <el-option v-for="item in form.examineChoice" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="考场">
                <div style="width: 276px;">
                    <el-radio-group v-model="form.room">
                        <el-radio :label="0">全部</el-radio>
                        <el-radio :label="1">单科考场</el-radio>
                        <el-radio :label="2">综合考场</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item label="考试大类">
                <el-select v-model="form.exam" filterable placeholder="请选择考试大类" :clearable="true">
                    <el-option v-for="item in form.examOpt" :key="item.id" :label="item.exam_name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择机构">
                <el-select v-model="form.mechanism" filterable placeholder="请输入并选择" :clearable="true" @change="mechanismChange">
                    <el-option v-for="item in form.mechanismChoose" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关键字">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <div class="" style="margin-left: 22px;margin-top: 5px;margin-bottom: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </el-form>
        <div class="" style="margin-left:22px ;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <!-- <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                @click="newlyAdded">
                新增
            </el-button> -->
            <button class="print" @click="print">打印预览</button>
            <button class="derive" @click="derive">导出到Excel</button>
            <button class="examine" @click="auditJournal">审核日志</button>
            <button class="distribute" @click="distribute">分配单科考场</button>
            <button class="org_stu" @click="orgstu">机构学员分配</button>
        </div>

        <!-- 分配单科考场弹窗 -->
        <el-dialog title="分配单科考场" width="550px" :visible.sync="visible">
            <el-form :label-position="labelPosition" :rules="rule" ref="list" class="form-1" label-width="120px"
                :model="list">
                <el-form-item label="选择考试大类" prop="bigCalss">
                    <el-select v-model="list.bigCalss" filterable placeholder="请输入并选择考试大类" :clearable="true">
                        <el-option v-for="item in list.bigCalssChose" :key="item.id" :label="item.exam_name	"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <div class="footer">
                <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submit('list')"> 提交 </el-button>
            	   <el-button style="background-color: #f5f5f5;color: #666;" @click="reset('list')">取消</el-button>
            </div>
        </el-dialog>


        <!-- 表格 -->
        <el-table :data="tableData" border stripe @selection-change="checkAll" style="width:98%;flex: 1;" height="650"
            id="out-table" @sort-change="abc">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop="status" sortable="custom" label="审核状态" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">未审核</span>
                    <span v-else-if="scope.row.status==2">已审核</span>
                </template>
            </el-table-column>
            <el-table-column prop="exam_type" sortable="custom" label="考场类A" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.exam_type==1">单科考场</span>
                    <span v-else-if="scope.row.exam_type==2">综合考场</span>
                </template>
            </el-table-column>
            <el-table-column prop="arrange_date" sortable="custom" label="制单日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="area_name" sortable="custom" label="考场名称" align="center" width="150">
            </el-table-column>
            <el-table-column prop="area_type" sortable="custom" label="考场类B" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_teacher" sortable="custom" label="监考老师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="org_name" sortable="custom" label="科目/机构" align="center" width="200">
            </el-table-column>
            <el-table-column prop="level" sortable="custom" label="等级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_date" sortable="custom" label="考试日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_starttime" sortable="custom" label="开始时间" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_endtime" sortable="custom" label="结束时间" align="center" width="120">
            </el-table-column>
            <el-table-column prop="num" sortable="custom" label="计划人数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="num2" sortable="custom" label="实际人数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="agroup" sortable="custom" label="A分组码" align="center" width="100">
            </el-table-column>
            <el-table-column prop="bgroup" sortable="custom" label="B分组码" align="center" width="100">
            </el-table-column>
            <el-table-column prop="cancel_numa" sortable="custom" label="取消人数A" align="center" width="120">
            </el-table-column>
            <el-table-column prop="total" sortable="custom" label="记录数" align="center" width="100">
            </el-table-column>
            <el-table-column prop="examarea_rate" sortable="custom" label="考场使用率(%)" align="center" width="150">
            </el-table-column>
            <el-table-column prop="cancel_numb" sortable="custom" label="取消人数B" align="center" width="120">
            </el-table-column>
            <el-table-column prop="defect_num" sortable="custom" label="缺考人数B" align="center" width="120">
            </el-table-column>
            <el-table-column prop="area_address" sortable="custom" label="地址" align="center" width="240"
                :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="number" sortable="custom" label="安排单号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="mcode" sortable="custom" label="助记码" align="center" width="100">
            </el-table-column>
            <el-table-column prop="add_aid" sortable="custom" label="录入人" align="center" width="100">
            </el-table-column>
            <el-table-column prop="add_time" sortable="custom" label="录入时间" align="center" width="180">
            </el-table-column>
            <el-table-column prop="save_aid" sortable="custom" label="修改人" align="center" width="100">
            </el-table-column>
            <el-table-column prop="save_time" sortable="custom" label="最近修改时间" align="center" width="180">
            </el-table-column>
            <el-table-column label="操作" align="center" width="280" fixed="right">
                <template slot-scope="scope">
                    <el-button class="edit" icon="el-icon-edit" size="mini" @click="edit(scope.$index,scope.row)">
                        编辑
                    </el-button>
                    <el-button class="stu" icon="el-icon-user-solid" size="mini"
                        @click="trainee(scope.$index,scope.row)">
                        学员
                    </el-button>
                    <el-button class="verify" icon="el-icon-s-check" size="mini"
                        @click="verify(scope.$index,scope.row)">
                        审核
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 审核弹框 -->
        <el-dialog title="审核确认" width="667px" :visible.sync="dialogFormVisible">
            <el-divider></el-divider>
            <el-form :label-position="labelPosition" :rules="rules" ref="ruleForm" class="form-1" label-width="120px"
                :model="ruleForm">
                <el-form-item label="审核意见" prop="examineIdea">
                    <el-select v-model="ruleForm.examineIdea" placeholder="请选择" :clearable="true">
                        <el-option v-for="item in ruleForm.examineIdeaChose" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <div class="footer">
                <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('ruleForm')"> 提交
                </el-button>
                <el-button style="background-color: #f5f5f5;color: #666;" @click="resetForm('ruleForm')">取消</el-button>
            </div>
        </el-dialog>

        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50, 100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>

        <!-- 打印预览弹窗 -->
        <el-dialog width="900px" :visible.sync="dialog" id="dialog">
            <div id="printAdmission">
                <!-- 点名表内容 -->
                <div class="rollcall" v-for="(item,index) in rollcall" :key="index">
                    <!-- 头部 -->
                    <h2>{{ item.year }}年 中国美术学院社会美术水平考试 点名表</h2>
                    <div class="center">
                        <div class="c_1">
                            考场名称 : {{ item.site_name }}
                        </div>
                       
                        <div class="c_2">
                            共 {{ item.sum_page }} 页-第 {{ index+1 }} 页
                        </div>
                    </div>
                    <!-- 中间 -->
                    <div class="center">
                        <div class="c_1">
                            机构 : {{org_name}}
                        </div>
                        <div class="c_2">
                            考试时间 : {{ item.exam2_date }}
                        </div>
                    </div>
                    
                    <!-- 中间 -->
                    <div class="center">
                        <div class="c_1">
                            监考老师 : {{ item.teacher }}
                        </div>
                    </div>
                    <!-- 表格 -->
                    <el-table :data="item.list" border :header-cell-style="{background:'#ffff7f',color:'#333'}">
                        <el-table-column prop="number" label="序号" align="center" width="40">
                        </el-table-column>
                        <el-table-column prop="certificate_no" label="准考证号" align="center" width="150"
                            :show-overflow-tooltip='true'>
                        </el-table-column>
                        <el-table-column prop="name" label="姓名" align="center" width="80" :show-overflow-tooltip='true'>
                        </el-table-column>
                        <el-table-column prop="english_name" label="拼音名" align="center" width="100"
                            :show-overflow-tooltip='true'>
                        </el-table-column>
                        <el-table-column prop="gender" label="性别" align="center" width="40">
                        </el-table-column>
                        <el-table-column prop="nation" label="民族" align="center" width="50">
                        </el-table-column>
                        <el-table-column prop="birthdate" label="出生日期" align="center" width="90">
                        </el-table-column>
                        <el-table-column prop="course" label="报考专业" align="center" width="70">
                        </el-table-column>
                        <el-table-column prop="exam_level" label="报考级别" align="center" width="65">
                        </el-table-column>
                        <el-table-column prop="is_deputy" label="副证" align="center" width="30">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="record">
                    <div class="">
                        <span>考生数量：</span><input type="" :value="sum" disabled />
                    </div>
                    <div class="">
                        <span>实考人数：</span><input type="" value="" disabled />
                    </div>
                    <div class="">
                        <span>缺考人数：</span><input type="" value="" disabled />
                    </div>
                    <div class="">
                        <span>监考签名：</span><input type="" value="" disabled />
                    </div>
                </div>
                <p style="color: #333;padding:15px 0px;font-size: 15px;">
                    <span style="font-weight: bold;">
                        备注说明 :
                    </span>
                    <input type="text" value=""
                        style="width: 86%;border: none;border-bottom: 1px solid #333;outline: none;background: #fff;"
                        disabled />
                </p>
            </div>
            <!-- 底部打印按钮 -->
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog=false">取 消</el-button>
                <el-button style="background: #18BC9C;color: #fff;" v-print="'#printAdmission'">
                    打 印
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    makerDate: "", //制单日期
                    testDate: "", //考试日期
                    roomName: "", //考场名称
                    examine: "", //审核状态
                    examineChoice: [{
                            label: "全部",
                            value: 0
                        },
                        {
                            label: "未审核",
                            value: 1
                        },
                        {
                            label: "已审核",
                            value: 2
                        }
                    ], //审核状态选择
                    condition: 0, //考试状态
                    room: 0, //考场
                    arrange: 1, //安排方
                    exam: '', //考试大类
                    examOpt: [], //考试大类选项，
                    search: '' ,//关键字
                    mechanism:null, //机构
                    mechanismChoose: [
                        
                    ] ,//机构选择
                },
                org_name:"",
                currentPage: 1,
                currentLimit: 20,
                total: 0,
                visible: false,
                tableData: [],
                id: "", //id
                ids: [],
                uid: "", //审核所需id
                labelPosition: 'right',
                dialogFormVisible: false,
                ruleForm: {
                    examineIdea: '', //审核意见
                    examineIdeaChose: [{
                            label: '确认',
                            value: '1'
                        },
                        {
                            label: '不确认',
                            value: '2'
                        },
                    ] //审核意见选择
                },
                rules: {
                    examineIdea: [{
                        required: true,
                        message: '请选择审核意见',
                        trigger: 'change'
                    }],
                },
                list: {
                    bigCalss: '', //考试大类
                    bigCalssChose: [

                    ] //考试大类选择
                },
                rule: {
                    bigCalss: [{
                        required: true,
                        message: '请选择考试大类',
                        trigger: 'change'
                    }],
                },
                dialog: false, //判断打印预览是否开启
                rollcall: [{
                    // year:"2021",//年
                    // room_name:"龙岗区龙河路19号东方明珠城方茄城2楼小童大作艺术家园( 龙岗小童 )",//考场名称
                    // count:"5",//总页数
                    // page:"1",//第几页
                    // test_time:"2021-07-11( 00:00 -- 00:01 )",//考试时间
                    // teacher:"测试胡",//监考老师
                    list: [{
                        // date: ' MH3.21070011-XTDZ',
                        // name: '王小虎',
                        // name2:"wang xiao hu",
                        // address: '上海市-普陀区-金沙江',
                        // sex:"男",
                        // nation:"汉族",//民族
                        // birthday:"2001-12-12",
                        // major:"漫画漫画",//专业
                        // level:"三级",//级别
                    }]
                }],
                sum: "", //
                bool: false,
                flag: false,
                arr: []
            }
        },
        created() {
            this.getList()
                        // 选择机构
            this.$request({
                url: "/api/mechanism/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                this.form.mechanismChoose = res.data.list
                this.form.mechanismChoose.unshift({
                    name:'全部',id:''
                })
            })
            // 考试大类
            this.$request({
                url: '/api/ExamCategory/list',
                method: 'POST',
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log(res,"考试大类")
                if (res.code == 1) {
                    this.list.bigCalssChose = res.data.list
                    this.form.examOpt = res.data.list
                }
            })
        },
        activated() {
            this.getList()
        },
        methods: {
            mechanismChange(id){
                if(id){
                    const org = this. form.mechanismChoose.find(e=>e.id === id)
                this.org_name = org ? org.name : ""
                } else {
                    this.org_name = ''
                }
                
            },
            getList() { //获取列表
                this.$request({
                    url: '/api/examarrange/list',
                    method: 'POST',
                    data: {
                        page: this.currentPage,
                        limit: this.currentLimit,
                        arrange_date: this.form.makerDate, //制单日期
                        exam_date: this.form.testDate, //考试日期
                        status: this.form.examine, //审核状态
                        exam_type: this.form.room, //考场
                        apply_category: this.form.exam,
                        sort: this.arr,
                        org_id: this.form.mechanism ,//机构id
                        search: this.form.search
                    }
                }).then(res => {
                    console.log(res, '列表')
                    if (res.code == 1) {
                        this.tableData = res.data.list
                        this.total = res.data.total
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit = val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addArrange"
                })
            },
            edit(index, row) { //编辑
                this.$router.push({
                    path: "editArrange",
                    query: {
                        id: row.id
                    }
                })
            },
            trainee(index, row) { //学员跳转
                this.$router.push({
                    path: "studentAdmin",
                    query: {
                        id: row.id,
                        exam_type: row.exam_type,
                        course_id: row.course_id,
                        exam_level: row.level
                    }
                })
            },
            checkAll(selection) { //获取批量id
                // console.log(selection)
                this.id = ''
                this.ids = []
                selection.forEach(item => {
                    this.id = item.id
                    this.ids.push(item.id)
                })
                // console.log(this.id)
            },
            auditJournal() { //审核日记
                this.$router.push({
                    path: "auditJournal",
                    query: {
                        id: this.id
                    }
                })
            },
            verify(index, row) {
                this.dialogFormVisible = true
                this.uid = row.id
            },
            submitForm(ruleForm) { //审核提交
                this.$refs[ruleForm].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.ruleForm)
                        if (this.bool) {
                            return
                        }
                        this.bool = true
                        this.$request({
                            url: '/api/examarrange/verify',
                            method: 'POST',
                            data: {
                                id: this.uid,
                                status: this.ruleForm.examineIdea
                            }
                        }).then(res => {
                            if (res.code == 1) {
                                this.$message({
                                    message: res.msg,
                                    type: 'success'
                                })
                                this.dialogFormVisible = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                                this.dialogFormVisible = false
                            }
                        }).catch(() => {
                            this.bool = false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(ruleForm) { //审核取消
                this.dialogFormVisible = false
                this.$refs[ruleForm].resetFields();
            },
            query() { //查询
                this.currentPage = 1
                this.getList()
            },
            Reset() { //重置
                this.form.makerDate = '' //制单日期
                this.form.testDate = '' //考试日期
                this.form.examine = '' //审核状态
                this.form.room = 0 //考场
                this.form.exam = ''
                this.form.search=''//关键字
                this.form.mechanism = '' //机构id
                this.getList()
            },
            derive() { //导出
                location.href = `https://artxt.szart.cn/api/public/index.php/api/examarrange/list?
                page=${this.currentPage}&limit=${this.currentLimit}&arrange_date=${this.form.makerDate}&exam_date=${this.form.testDate}&
                status=${this.form.examine}&exam_type=${this.form.room}&apply_category=${this.form.exam}&sort=${this.arr}&
                search=${this.form.search}&org_id=${this.form.mechanism}`
            },
            print() { //打印预览
                this.dialog = true
                // console.log(this.form.print)
                this.$request({
                    url: '/api/statistics/callroll',
                    method: 'POST',
                    data: {
                        examarea_id: this.id
                    }
                }).then(res => {
                    console.log(res)
                    if (res.code == 1) {
                        this.rollcall = res.data
                        this.sum = res.data[0].count
                    } else {
                        this.dialog = false
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })

                    }
                })
            },
            distribute() { //分配单科考场
                this.visible = true
            },
            submit(list) {
                this.$refs[list].validate((valid) => {
                    if (valid) {
                        // if(this.flag){
                        //     return
                        // }
                        // this.flag=true

                        this.$request({
                            url: '/api/examarrange/generate',
                            method: 'POST',
                            data: {
                                id: this.list.bigCalss
                            }
                        }).then(res => {
                            if (res.code == 1) {
                                this.visible = false
                                this.getList()
                                this.$message({
                                    message: res.msg,
                                    type: 'success'
                                })

                            } else {
                                this.visible = false
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })

                            }
                        }).catch(() => {
                            // this.flag=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            reset(list) {
                this.visible = false
                this.$refs[list].resetFields();
            },
            orgstu() { //机构学员分配
                this.$request({
                    url: '/api/examarrange/fp_student',
                    method: 'POST',
                    data: {
                        id: this.ids
                    }
                }).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            },

            // 姓名排序
            fn2(a, b) {
                return a.exam_teacher.localeCompare(b.exam_teacher)
            },
            // 姓名排序
            fn3(a, b) {
                return a.area_name.localeCompare(b.area_name)
            },
            //排序
            abc(i) {
                console.log(i)
                this.tableData = []
                this.arr = []
                this.arr.push({
                    title: i.prop,
                    order: i.order
                })
                console.log(this.arr)
                this.getList()
            },



        }
    }
</script>

<style scoped="scoped">
    .Arrange {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 查询部分 */
    .form {
        padding-top: 34px;
    }

    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    .form {
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;

    }

    ::v-deep .el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }

    ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }

    /* 刷新按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }

    /* 打印预览 */
    .print {
        display: inline-block;
        padding-left: 10px;
        width: 94px;
        height: 30px;
        border-radius: 3px;
        vertical-align: middle;
        background: url(../../assets/print.png)8px 9px no-repeat #18BC9C;
        border: none;
        color: #FFFFFF;
        margin: 0px 10px;
        cursor: pointer;
    }

    /* 导出按钮 */
    .derive {
        display: inline-block;
        width: 113px;
        height: 30px;
        padding-left: 10px;
        vertical-align: middle;
        background: url(../../assets/export.png) 6px 6px no-repeat #FF7300;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        margin-right: 10px;
        cursor: pointer;
    }

    /* 审核日记按钮 */
    .examine {
        display: inline-block;
        width: 88px;
        height: 30px;
        padding-left: 10px;
        vertical-align: middle;
        background: url(../../assets/examine.png)6px 8px no-repeat #18BC9C;
        border-radius: 3px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
    }

    /* 分配单科考场 */
    .distribute {
        display: inline-block;
        width: 95px;
        height: 30px;
        vertical-align: middle;
        border: none;
        cursor: pointer;
        border-radius: 3px;
        color: #FFFFFF;
        margin-left: 10px;
        background: #18BC9C;
    }

    .org_stu {
        display: inline-block;
        width: 95px;
        height: 30px;
        vertical-align: middle;
        border: none;
        cursor: pointer;
        border-radius: 3px;
        color: #FFFFFF;
        margin-left: 10px;
        background: #FF7300;

    }

    .form-1 {
        padding: 10px !important;
        width: 500px !important;
        margin: 0px auto !important;
        margin-top: 5px !important;
    }

    .form-1::v-deep .el-input__inner {
        width: 300px;
        height: 37px;
    }

    .form-1 ::v-deep .el-form-item__label {
        color: #000000;
    }

    .footer {
        padding-bottom: 20px;
    }

    .dialog-footer {
        text-align: center;
    }

    ::v-deep .el-dialog__title {
        font-weight: bold;
    }

    /* ::v-deep .el-dialog__footer{
        padding: 0px !important;
    } */
    ::v-deep.el-dialog__header {
        border: #000000 1px solid;
    }

    .el-divider--horizontal {
        margin: 0px;
    }

    ::v-deep .footer {
        margin: 15px 0px !important;
        text-align: center !important;
        /* height: 50px !important; */
    }

    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }

    /* 编辑按钮 */
    .edit {
        background: #18BC9C;
        color: #FFFFFF;
    }

    /* 学员按钮 */
    .stu {
        background: #F0AD4E;
        color: #FFFFFF;
    }

    /* 审核按钮 */
    .verify {
        background: #FF7300;
        color: #FFFFFF;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }

    /* 打印预览 */
    #dialog ::v-deep .el-dialog__body {
        padding: 0px 15px;
    }

    #dialog ::v-deep .el-dialog__footer {
        padding: 10px 20px 20px !important;
        text-align: center;
    }

    #dialog #printAdmission {
        width: 850px;
    }

    .rollcall {
        width: 100%;
        color: #000;
    }

    /* 标题 */
    .rollcall h2 {
        text-align: center;
        color: #333;
    }

    /* 头部内容 */
    .rollcall .top {
        /* padding: 0px 10px; */
        margin-top: 10px;
        font-size: 16px;
        display: flex;
        justify-content: space-around;
    }

    .rollcall .center {
        padding: 6px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
    }

    .rollcall .below {
        font-size: 16px;
        padding-left: 10px;
    }

    /* 表格 */
    .rollcall .el-table {
        width: 717px !important;
        margin: 0px auto;
        margin-top: 10px;
        padding: 0px;
    }

    .rollcall ::v-deep .el-table__cell {
        height: 30px;
        padding: 0px !important;
    }

    .rollcall ::v-deep .el-table th.el-table__cell>.cell {
        padding: 0px !important;
    }

    .rollcall ::v-deep .el-table .cell {
        padding: 0px !important;
    }

    .rollcall ::v-deep .el-table--border,
    .el-table--group {
        border: 1px solid #000000;
        border-color: #000;
    }

    .rollcall:nth-child(1){
  margin-bottom:60px;
}

    /* 数据 */
    .record {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        color: #333;
    }

    .record span {
        font-weight: bold;
    }

    .record input {
        outline: none;
        border: none;
        border-bottom: 1px solid #333;
        background: #fff;
        width: 90px;
        color: #333;
        padding-left: 5px;
    }
</style>
